<template>
  <span v-if="refco" class="product-refco" :data-test-id="elementTest + '-refco'">
    <span class="product-refco-label" :data-test-id="elementTest + '-refco-label'">{{ $t('product.ref') }}</span>
    <span class="product-refco-value" :data-test-id="elementTest + '-refco-value'">{{ refco }}</span>
  </span>
</template>

<script>
export default {
  name: 'RefcoLabel',
  props: {
    refco: { type: String, default: null },
    elementTest: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.product-refco {
  font-weight: 900;
  font-size: pxToRem(12px);
  display: flex;
  border-radius: 3px;

  &-label {
    color: var(--grey-light);
    opacity: 0.6;
    margin-right: 5px;
  }

  &-value {
    color: var(--blue-dark);
  }

  &.no-label {
    .product-refco-label {
      display: none;
    }
  }
}
</style>
